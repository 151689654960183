import logo from './logo.svg';
import './App.css';
import Login from './Components/Login';
import Header from './Components/Header';
function App() {
  return (
    <div>
      {/* <Header /> */}
      <div className="App">
        <Login />
      </div>
    </div>
  );
}

export default App;
