import './style.css';
import Player from '../Player';
import React, { useState, useEffect } from 'react';
import Personalize from '../Personalize';
import NumberSelect from '../NumberSelect';
import { Collapse, Input, Radio, Space } from 'antd';
import 'antd/dist/antd.css';

function PersonalizeLanding(props) {
  const [isZoom, setIsZoom] = useState(false);
  const [selected, setSelected] = useState(props.selected);
  const [configurator, setConfigurator] = useState();
  // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const [step, setStep] = useState('main');

  const { onBack } = props;

  const theme = {
    collapse: 'ReactCollapse--collapse',
    content: 'ReactCollapse--content',
  };

  useEffect(() => {
    // apply2DSpin({ attrName: 'Rotation', direction: 1 })(window.player)
  }, []);

  return (
    <div>
      <div className="logged-in">
        <div className="left" style={{ width: '70%' }}>
          <div className="right-content">
            <Player
              selected={props.selected}
              num={props.num}
              assetId={props.assetId}
              onloaded={(configurator) => setConfigurator(configurator)}
            />
            <center>
              {configurator ? (
                <div className="right-buttons">
                  <img
                    src="https://solutions-engineering.s3.amazonaws.com/mh/360.png"
                    style={{ height: '30px', width: '30px' }}
                    onClick={() => {
                      console.log('click');
                      let el =
                        document.getElementsByClassName('canvas___BrMgq')[0];

                      el.style.pointerEvents = 'auto';
                      window.configurator.setConfiguration({
                        'Camera Select': '360',
                      });
                    }}
                    className="player-buttons"
                  />
                  <img
                    src="https://solutions-engineering.s3.amazonaws.com/mh/detail2.png"
                    style={{ height: '30px', width: '30px' }}
                    onClick={() => {
                      console.log('click');
                      window.configurator.setConfiguration({
                        'Camera Select': 'ZOOM',
                        'Object Angle': 0,
                      });
                      setIsZoom(true);
                      let el =
                        document.getElementsByClassName('canvas___BrMgq')[0];
                      console.log(el);
                      el.style.pointerEvents = 'none';
                    }}
                    className="player-buttons"
                  />
                  {/* <img
                  src="https://solutions-engineering.s3.amazonaws.com/mh/detail1.png"
                  onClick={() => {
                    console.log('click');
                    window.configurator.setConfiguration({
                      Camera: 'detail2',
                      Rotation: 0,
                    });
                    setIsZoom(true);
                    let el =
                      document.getElementsByClassName('canvas___BrMgq')[0];

                    el.style.pointerEvents = 'none';
                  }}
                  className="player-buttons"
                /> */}
                  {/* <p className="right-btn">x PERSONALISE</p>
              <p className="right-btn">x DETAILS</p>
              <p className="mh-title right-btn">x ADD</p> */}
                </div>
              ) : null}
            </center>
          </div>
        </div>
        <div className="right">
          <div className="left-content">
            <h1 className="center">
              {'MAKE YOUR OWN '}
              {selected.toUpperCase()}
            </h1>

            <h4 className="mh-title center">
              {props.locale === 'US'
                ? 'PRIVILEGE OF PERSONALISATION'
                : 'PRIVILÈGE DE PERSONNALISATION'}
            </h4>
            {/* <p className="center">
              {step === 'main'
                ? props.locale === 'US'
                  ? 'Each of the 250 decanters can be personalized by engraving message on the last of the eight rings. Add your name to the exceptional.'
                  : "Chacune des 250 carafes peut être personnalisée en gravant un message sur le dernier des huit anneaux. Ajoutez votre nom à l'exceptionnel."
                : null}
            </p> */}
            {step === 'main' && configurator ? (
              <Collapse accordion>
                {configurator.getAttributes().map((attr, i) => {
                  console.log(attr);
                  const { type, name } = attr;
                  if (name !== 'Camera Select' && name !== 'Object Angle')
                    return (
                      <Collapse.Panel header={name} key={i}>
                        {attr.values && attr.values.length ? (
                          <Radio.Group
                            id={name}
                            onChange={(e) => {
                              configurator.setConfiguration({
                                [name]:
                                  type === 'Asset'
                                    ? { assetId: e.target.value }
                                    : e.target.value,
                              });
                            }}
                          >
                            <Space direction="vertical">
                              {attr.values.map((value, i) => {
                                return type === 'Asset' ? (
                                  <Radio key={i} value={value.assetId}>
                                    {value.name}
                                  </Radio>
                                ) : (
                                  <Radio key={i} value={value}>
                                    {value}
                                  </Radio>
                                );
                              })}
                            </Space>
                          </Radio.Group>
                        ) : type === 'String' ? (
                          <Input
                            placeholder=""
                            onChange={(e) => {
                              console.log(name, e.target.value);
                              configurator.setConfiguration({
                                [name]: e.target.value || '',
                              });
                            }}
                          />
                        ) : null}
                      </Collapse.Panel>
                    );
                })}
              </Collapse>
            ) : null}

            {/* <h3 className="center">
              {props.locale === 'US' ? '$' + priceUSD : '€' + priceEuro}
            </h3> */}
          </div>
        </div>
      </div>

      <div style={{ margin: '10px' }}>
        <a className="circle-title" href="/" onClick={() => onBack(0)}>
          <span className="badge" href="/">
            {'<'}
          </span>
          <span style={{ marginLeft: '10px' }}>{'BACK'}</span>
        </a>
      </div>
      <div className="logout-button">
        <button
          onClick={() => {
            localStorage.removeItem('user');
            window.location.reload();
          }}
          className="log-out-btn"
        >
          Log out
        </button>
      </div>
    </div>
  );
}

export default PersonalizeLanding;
