import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import PersonalizeLanding from '../PersonalizeLanding';
import Flag from 'react-flagpack';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper';

import './style.css';
import { deviceDetect } from 'react-device-detect';

const PRODUCTS = {
  'Hennessy 8': '81d537d7-80c6-4c3c-8f5d-30179d246c53',
  'Dame Jeanne': 'b0f31278-2294-47b4-ab3c-df32bcd471de',
  'Paradis Baumer': '513ba12f-ee3e-4727-be3c-e40fafbc818a',
  'XO by Kim Jones': '50428cdf-f731-475c-b756-8f270fec66cd',
  'Edition Particuliere': 'c8ab61d4-492a-4552-a105-ca7f88fa5e9c',
  'Edition Particuliere 2': '31c90a19-2194-479b-885c-02708736ff87',
};

function Landing() {
  const [active, setActive] = useState(null);
  const [name, setName] = useState('Hennessy 8');
  const [step, setStep] = useState(0);
  // const [hasLoaded, setHasLoaded] = useState(false)
  const [locale, setLocale] = useState('US');
  useEffect(() => {}, [name]);

  const products = Object.keys(PRODUCTS);

  return (
    <div>
      <center>
        {step === 0 ? (
          <div className="landing">
            <h1 className="mh-title">{'SELECT YOUR PRODUCT'}</h1>
            <div>
              <h4>{name}</h4>
              <Swiper
                pagination={
                  {
                    // clickable: true,
                  }
                }
                navigation={true}
                slidesPerView={3}
                spaceBetween={30}
                slidesPerGroup={1}
                centeredSlides={true}
                loop={true}
                loopFillGroupWithBlank={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                onSlideChange={(e) => {
                  setActive(e.activeIndex % products.length);
                  setName(
                    Object.keys(PRODUCTS)[e.activeIndex % products.length]
                  );
                }}
              >
                {Object.keys(PRODUCTS).map((name) => {
                  const imageName = './' + name.replace(/ /g, '_') + '.jpg';
                  return (
                    <SwiperSlide
                      onClick={() => {
                        setName(name);
                        setStep(1);
                      }}
                    >
                      <img src={imageName} className="swiper-image" />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <br />
            <div style={{ margin: '10px' }}>
              <a className="circle-title" href="#" onClick={() => setStep(1)}>
                <span className="badge" href="/">
                  {'>'}
                </span>
                <span style={{ marginLeft: '10px' }}>
                  {locale === 'US' ? 'PROCEED' : 'PROCÉDÉ'}
                </span>
              </a>
            </div>
          </div>
        ) : (
          <div>
            <PersonalizeLanding
              assetId={PRODUCTS[name]}
              selected={name}
              locale={locale}
              onBack={setStep}
            />
          </div>
        )}
      </center>
    </div>
  );
}

export default Landing;
