import React, { useState, useEffect } from 'react';
import './style.css';
import { apply2DSpin } from '../../Helpers/apply2DSpin';
function Player(props) {
  const { assetId, onloaded } = props;
  useEffect(() => {
    window
      .threekitPlayer({
        authToken: 'b40f650e-a09a-4a77-9746-d1f5384b0e19',
        el: document.getElementById('player'),
        assetId: assetId || '81d537d7-80c6-4c3c-8f5d-30179d246c53',
        showLoadingThumbnail: false,
        showConfigurator: false,
        showAR: false,
      })
      .then(async function (player) {
        window.player = player;
        player.enableApi('player');
        window.configurator = await player.getConfigurator();
        await window.configurator.prefetchAttributes(['Object Angle']);

        onloaded(window.configurator);

        apply2DSpin({ attrName: 'Object Angle', direction: 1 })(player);
      });
  }, []);

  return (
    <div className="player-container">
      <div id="player"></div>
    </div>
  );
}

export default Player;
